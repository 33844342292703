import React from "react";
import styled from "styled-components";
import { Header } from "./Header";
import { Footer } from "./Footer";
import { GlobalStyles } from "./GlobalStyle";
import { SiteMetadata } from "./SiteMetadata";

const Main = styled.main`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 1rem auto;
  width: 100%;
  max-width: 90vw;
`;

export const Layout: React.FC = ({ children }) => {
  return (
    <>
      <SiteMetadata />
      <GlobalStyles />
      <Main>
        <Header />
        <section>{children}</section>
        <Footer />
      </Main>
    </>
  );
};
