import React from "react";
import styled from "styled-components";

const SiteFooter = styled.footer`
  text-align: center;
  margin-top: 1rem;
`;

export const Footer: React.FC = () => {
  return (
    <SiteFooter className="margin-left-right">
      <hr />
      <h5>© 2021 Mapna Inspection LLC. All Rights Reserved.</h5>
      <h6 style={{ fontSize: "0.5em", color: "gray" }}>
        Created By Saeid Ostad
      </h6>
    </SiteFooter>
  );
};
