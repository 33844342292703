import React from "react";
import styled from "styled-components";
import { ContactInfo } from "./ContactInfo";

const SiteHeader = styled.header`
  text-align: center;
  font-family: "Audiowide", cursive;
  margin-bottom: 1rem;

  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 3fr 3fr;
    align-items: center;
  }
`;
const HeaderContact = styled.div`
  font-size: 0.75rem;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const Header: React.FC = () => {
  return (
    <SiteHeader>
      <h1 className="margin-left-right" style={{ letterSpacing: "0.75rem" }}>
        {`Mapna`.toUpperCase()}
      </h1>
      <h3 className="margin-left-right">Home Inspection</h3>
      <HeaderContact>
        <ContactInfo />
      </HeaderContact>
    </SiteHeader>
  );
};
