import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  html {
  font-size: 16px;
  @media screen and (min-width: 900px) {
    font-size: 18px;
  }
    
  @media screen and (min-width: 1200px) {
    font-size: 20px;
  }
  }
  body {
    padding : 0;
    margin: 0;
    font-family: "Open Sans";
  }
  h1{
    font-size: 2.5rem
  }
  .margin-left-right{
    margin: 0 1rem;
  }
`;
