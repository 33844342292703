import React from "react";

export const PhoneInfo: React.FC = () => {
  return (
    <div style={{ margin: "0.5rem 0" }}>
      <span>Annis: </span>
      <a href="tel:(404) 790-5363">(404) 790-5363</a>
    </div>
  );
};
