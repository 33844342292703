import React from "react";
import { Helmet } from "react-helmet";
import favicon from "../images/favicon/favicon.ico";
import { graphql, useStaticQuery } from "gatsby";

export const SiteMetadata = () => {
  const {
    site: {
      siteMetadata: { description, title },
    },
  } = useStaticQuery(graphql`
    query SiteMetadata {
      site {
        siteMetadata {
          siteUrl
          title
          description
        }
      }
    }
  `);
  const locale = "en";
  return (
    <Helmet defer={false} defaultTitle={title} titleTemplate={`%s | ${title}`}>
      <html lang={locale} />
      <link rel="icon" href={favicon} />
      <meta
        name="viewport"
        content="width=device-width,initial-scale=1,shrink-to-fit=no,viewport-fit=cover"
      />
      <meta name="description" content={description} />
    </Helmet>
  );
};
