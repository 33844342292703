import React from "react";
import { EmailInfo } from "./EmailInfo";
import { PhoneInfo } from "./PhoneInfo";

export const ContactInfo: React.FC = () => {
  return (
    <div
      className="contact-info"
      style={{
        display: "grid",
        justifyContent: "center",
        alignContent: "center",
      }}
    >
      <PhoneInfo />
      <EmailInfo />
    </div>
  );
};
